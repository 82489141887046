.button {
  display: inline-block;
  background-color: transparent;
  border: none;
  border-radius: 0.25em;
  outline: none;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  text-align: center;
  line-height: 54px;
  min-height: 54px;

  @include responsive(phone) {
    min-height: 50px;
  }

  &:disabled {
    background-color: #47474a;
    cursor: not-allowed;
  }

  // Button sizes
  &.wide {
    width: 100%;
  }

  // Button types
  &--primary {
    background: $primaryColor;
    color: $white;
    margin: 5px;

    &:hover,
    &:disabled {
      background: $primaryColor;
      opacity: 0.75;
    }
  }

  &--outlined {
    border: 1px solid $secondaryColor;
    color: $secondaryColor;
    margin: 5px;
  }

  // Button loader
  &--loading::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    border: 4px solid transparent;
    border-top-color: $white;
    border-radius: 50%;
    animation: button-loading-spinner 1s linear infinite;
  }

  &--loading &__text {
    visibility: hidden;
    opacity: 0;
  }
}
