.main-header {
  height: 100px;

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  & .nav-list {
    display: flex;
    justify-content: space-evenly;

    & li {
      margin-left: 60px;

      @include responsive(phone) {
        margin-left: 30px;
      }
    }

    & a {
      font-size: 16px;
      font-weight: 700;
      transition: color 0.15s ease-in;
      cursor: pointer;

      &.get-started {
        background-color: $primaryColor;
        color: $white;
        padding: 10px;
        border-radius: 4px;
        font-size: 14px;
        &:hover {
          color: $white;
          opacity: 0.75;
        }
      }

      &:hover,
      &.active {
        color: $primaryColor;
      }
    }
  }

  & .mobile-nav-list {
    width: 100%;
    background: $white;
    top: 100px;
    position: absolute;
    height: 0;
    z-index: 1;
    left: 0;
    opacity: 0;
    visibility: hidden;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    transition: height 400ms linear;

    &.show {
      height: 100vh;
      opacity: 1;
      visibility: visible;
    }

    & li {
      margin-bottom: 30px;
    }

    & a {
      font-size: 24px;
    }
  }

  & .button {
    font-size: 15px;
  }

  & .hamburger-button {
    height: 28px;
    outline: none;
    width: 30px;
    background-color: transparent;
    border: none;
    display: none;
    @include responsive(tabletPortrait) {
      display: block;
    }
  }
  &__icon {
    position: relative;
    display: none;
    margin: 0 auto;
    @include responsive(tabletPortrait) {
      display: block;
    }
    // &,
    &::before,
    &::after {
      width: 30px;
      height: 3px;
      border-radius: 2px;
      background: $accentColor;
      transition: transform 400ms ease-in-out;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
    }

    &::before {
      top: -0.8rem;
      right: 0;
      left: auto;
    }

    &::after {
      left: 0;
    }

    &.open {
      & {
        background-color: transparent;
      }
      &::before {
        transform: rotate(145deg);
      }

      &::after {
        width: 100%;
        transform: rotate(-145deg);
      }
    }
  }

  @include responsive(tabletLandscape) {
    padding: 0 20px;

    & .nav-list {
      display: none;
    }
  }
}
