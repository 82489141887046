*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
  color: $secondaryColor;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

strong {
  font-weight: 700;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: #333;
}

p {
  font-size: 1.4em;
  line-height: 1.5em;
}
