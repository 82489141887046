.features {
  padding: 80px 0;

  & .container {
    text-align: center;
  }

  & .title {
    font-size: 32px;
    margin-bottom: 80px;
    font-weight: 900;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    &-sub {
      grid-column: 1/4;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;

      & .feature {
        display: flex;
        justify-content: center;
        align-items: center;

        & > * {
          flex: 1;
        }
      }
    }

    & .feature {
      padding: 20px;

      &feature-second {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-text {
        text-align: left;
      }

      &:nth-child(1) {
        background-color: #fff5f5;
      }

      &:nth-child(2) {
        background-color: #ebf6ff;
      }

      &:nth-child(3) {
        background-color: #e2f7ff;
      }

      &:nth-child(4) {
        background-color: #fbf4ee;
      }

      &:nth-child(5) {
        background-color: #fbfbfb;
      }

      &-icon {
        margin-bottom: 20px;
        font-size: 48px;

        img {
          height: 80px;
          object-fit: contain;
        }
      }
      h4 {
        font-size: 22px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        color: #888;
      }
    }
  }
  @include responsive(tabletPortrait) {
    padding-top: 506px;
    &-grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
    &-grid-sub {
      grid-template-columns: repeat(1, 1fr);
      grid-column: auto;
      gap: 0;

      & .feature {
        display: block;
      }
    }
    & .feature {
      text-align: center;
      padding: 40px;
    }
  }
}

.payment-plans {
  background-color: #fbfbfc;
  padding: 100px 0;
  // text-align: center;

  & h2 {
    color: #185adb;
  }
  & p {
    color: $secondaryColor;
  }

  & h2 {
    margin-bottom: 40px;
    font-size: 35px;
  }

  & p {
    margin-bottom: 40px;
  }

  & a {
    background: white;
    padding: 15px 25px;
    color: $secondaryColor;
    font-size: 20px;
    font-weight: 700;
    border-radius: 4px;
  }

  & img {
    width: 25%;
  }

  & .text-container {
    width: 80%;
    margin-right: 199px;

    @include responsive(tabletPortrait) {
      margin-right: 0px;
    }
  }

  & .container {
    display: flex;

    @include responsive(tabletPortrait) {
      flex-direction: column-reverse;
      align-items: center;
      font-size: 10px;
    }
  }
}

.how-it-works {
  background-color: #185adb;
  padding: 100px 0;
  text-align: center;

  & h2,
  & p {
    color: white;
  }

  & h2 {
    margin-bottom: 40px;
    font-size: 36px;
  }

  & p {
    margin-bottom: 40px;

    @include responsive(tabletPortrait) {
      font-size: 16px;
    }
  }

  & a {
    background: white;
    padding: 15px 25px;
    color: $secondaryColor;
    font-size: 20px;
    font-weight: 700;
    border-radius: 4px;
  }
}

.testimonials {
  padding: 120px 0;
  & .titles {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    @include responsive(tabletPortrait) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  & .title {
    font-weight: 600;
    font-size: 27px;
    margin-bottom: 65px;

    @include responsive(tabletPortrait) {
      text-align-last: center;
    }
  }

  & .titleContainer {
    display: flex;
  }

  & .sub-title {
    color: $secondaryColor;
    font-size: 38px;
    margin-bottom: 20px;
    font-weight: 800;
    margin-top: 40px;

    & .alt-color {
      color: $primaryColor;
    }
  }

  & .icon {
    margin-left: 50px;
    margin-bottom: 125px;
    @include responsive(tabletPortrait) {
      display: none;
    }
  }

  & .carousel {
    width: 800px;

    @include responsive(tabletPortrait) {
      width: 350px;
    }
  }

  & .profile {
    display: flex;
    flex-direction: column;
  }

  & .content {
    display: flex;
  }

  & .oval {
    margin-top: 52px;
    width: 150px;
    height: 60px;
    background: #6495ed;
    border-radius: 100px / 100px;
    display: flex;
    justify-content: center;
  }

  .carousel .control-dots .dot {
    // border-radius: 0% !important;
    // width: 20px !important;
    // height: 2px !important;
    background: black;
  }

  & .profile-picture {
    // margin-top: -53px;
    margin-left: -130px;
    width: 105px;
  }

  & .info {
    margin-left: 40px;
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-name {
      font-weight: 800;
    }

    &-title {
      font-weight: 400;
      margin-top: 5px;
    }
  }

  & .message {
    margin-top: 25px;

    & p {
      font-size: 20px;
      color: #888;

      @include responsive(tabletPortrait) {
        font-size: 15px;
      }
    }
  }

  &-container {
    position: relative;
    display: flex;
    align-items: center;

    & .arrow {
      position: absolute;
      color: #888;
    }

    & .left-arrow {
      left: -100px;
    }

    & .right-arrow {
      right: -100px;
    }
  }

  & .testimony {
    display: flex;
    align-items: center;

    & .message {
      padding-right: 40px;

      & p {
        margin-bottom: 30px;
        line-height: 44px;
      }

      & h4 {
        font-size: 24px;
      }
    }
  }

  & img {
    width: 105px;
  }

  & .testifier-photo {
    height: 300px;
    width: 1000px;
    border-radius: 6px;

    img {
      border-radius: 6px;
      object-fit: cover;
    }
  }
}

.faqs {
  padding: 60px 0;
  & .title {
    font-size: 32px;
    margin-bottom: 80px;
    font-weight: 900;
    text-align: center;
  }

  &-list {
    & li {
      display: block;
      border: 1px solid #ddd;
      padding: 40px;
      border-radius: 4px;
      margin-bottom: 20px;
      height: 111px;
      transition: all 0.4s ease;

      & h3 {
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        & span.faq-toggle-btn {
          cursor: pointer;
        }
      }

      & p {
        visibility: hidden;
        opacity: 0;
        height: 0;
        transition: all 0.4s ease;
      }

      &.open {
        height: 200px;
        & h3 {
          color: $primaryColor;
        }
        & p.active {
          visibility: visible;
          opacity: 1;
          height: auto;
        }
      }
    }
  }

  @include responsive(tabletPortrait) {
    padding: 60px 20px;
    & .title {
      font-size: 24px;
      margin-bottom: 40px;
    }

    &-list {
      & li {
        padding: 20px;
        height: 85px;
        & h3 {
          font-size: 18px;
        }

        & p {
          font-size: 16px;
        }

        &.open {
          height: 140px;
        }
      }
    }
  }
}
