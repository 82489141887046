.big-hero {
  height: 100%;
  width: 100%;
  padding: 0px 0;

  @include responsive(tabletPortrait) {
    // padding: 60px 20px;
  }

  & .container {
    @include responsive(tabletPortrait) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-text {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    // padding-right: 60px;
    padding-top: 30px;

    h1 {
      font-weight: 900;
      font-size: 48px;
      margin-bottom: 20px;
      color: $secondaryColor;
    }
    & .alt-color {
      color: $primaryColor;
    }

    p {
      font-size: 22px;
      color: #888;
      margin-bottom: 40px;
    }
    a.button {
      width: 160px;
      font-size: 17px;
    }
    .helpline {
      font-size: 15px;
      font-weight: 600;
    }

    @include responsive(tabletPortrait) {
      align-items: center;
      text-align: center;
      padding: 29px;
      margin-bottom: 100px;

      h1 {
        font-size: 34px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  &-cta {
    display: flex;
    justify-content: flex-start;
    & .second {
      margin-left: 10px;
    }
    @include responsive(tabletPortrait) {
      flex-direction: column;
    }
  }

  &-image {
    height: 690px;
    width: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/images/Background.png);

    @include responsive(tabletPortrait) {
      height: 300px;
      width: auto;
    }
  }
}
