.logo {
  width: 150px;

  @include responsive(tabletLandscape) {
    width: 120px;
  }

  & img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  & h3 {
    font-size: 34px;
    font-weight: 900;
    letter-spacing: 1px;
    & span {
      color: $primaryColor;
    }
  }
}
