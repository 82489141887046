// Colors
$primaryColor: #185adb;
$secondaryColor: #0a1931;
$accentColor: #6a7584;
$danger: #ff4136;
$clouds: #ecf0f1;
$silver: #bdc3c7;
$white: #fff;
$asbestos: #7f8c8d;
$pomegranate: #c0392b;
$greenSea: #16a085;
$carrot: #e67e22;
