.form-group {
  position: relative;
  margin-bottom: 20px;

  @include responsive(phone) {
    margin-bottom: 10px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.has-sublink {
    & a {
      font-size: 1.6em;
      margin-top: 0.5em;
      text-align: center;
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  & span.form-input-msg {
    font-size: 15px;
    margin-top: 7px;
    display: inline-block;
    color: $danger;
  }

  & label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    position: relative;

    &.required:after {
      content: "*";
      color: $danger;
      margin-left: 3px;
    }
  }

  .select-container {
    position: relative;
    svg {
      position: absolute;
      top: 17px;
      right: 7px;
      font-size: 20px;
    }
  }

  & input[type="text"],
  & input[type="number"],
  & select {
    border: 2px solid #dedede;
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 0 1em;
    height: 50px;
    width: 100%;
    outline: none;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    -webkit-appearance: none;

    @include responsive(phone) {
      height: 45px;
    }

    &.input-error {
      border-color: $pomegranate;
      background-color: #f3e6e6;
    }

    &[type="password"] {
      font: 18px Verdana, sans-serif;
      letter-spacing: 2px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  & .checkbox-area {
    margin-bottom: 15px;

    & label {
      margin-bottom: 0;
      font-size: 16px;
      display: flex;

      & .checkbox-label {
        padding-left: 30px;
        cursor: pointer;
      }

      & a {
        color: $primaryColor;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    & span.required {
      color: $danger;
    }
  }

  & input[type="checkbox"] {
    height: 1.5em;
    width: 1.5em;
    margin-top: 5px;
    position: absolute;
  }
}
