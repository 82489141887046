.spinner {
  box-sizing: border-box;
  height: 35px;
  width: 35px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 10;
    stroke-linecap: round;
    overflow: visible;
    animation: rotate 1.1s linear 0s infinite;
    & path {
      stroke: $primaryColor;
    }
    & circle {
      fill: none;
    }
  }
}
