@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulsate {
  from {
    transform: scale(1.5, 1.5);
  }
  to {
    transform: scale(1, 1);
  }
}
