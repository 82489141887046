footer {
  background-color: $secondaryColor;
  padding: 60px 0;
  & .container {
    display: flex;
    justify-content: space-between;

    & > * {
      flex: 1;
    }
  }

  & .logo-copyright {
    display: none;
  }

  & .copyright {
    font-size: 15px;
    color: #888;
  }

  & .logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-list {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    margin-bottom: 20px;

    a {
      color: #fff;
    }
  }
  .social-media {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & svg {
      margin-left: 20px;
      color: #888;
    }

    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      color: #fff;
      background-color: $secondaryColor;
    }
  }
  @include responsive(tabletLandscape) {
    padding: 30px;

    & .container {
      flex-direction: column;
    }

    & .m-copyright {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }

    & .nav-list {
      flex-direction: column;

      li {
        margin-bottom: 20px;
      }
    }

    & .social-media {
      justify-content: center;
    }
  }
}
